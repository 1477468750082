






























































































































































































































































































































import { buildTruckingInvoiceArResponseDto } from "@/builder";
import { DisplayBoolean } from "@/components/DisplayBoolean";
import InvoicePrintModal from "@/components/InvoiceAr/InvoicePrintModal.vue";
import FormTruckingInvoiceStatusView from "@/components/TruckingAccountReceivable/FormTruckingInvoiceStatusView.vue";
import currencyFilter from "@/filters/currency.filter";
import { generateUUID } from "@/helpers/uuid";
import { useTruckingAccountReceivable } from "@/hooks";
import { TruckingAccountReceivableFormStatusDetailInvoiceState } from "@/models/interface/trucking-account-receivable";
import { Decimal } from "decimal.js-light";
import Vue from "vue";

export default Vue.extend({
  name: "InvoiceDetailPage",
  components: {
    DisplayBoolean,
    FormTruckingInvoiceStatusView,
    InvoicePrintModal,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      state: buildTruckingInvoiceArResponseDto(),
      columns: [
        {
          title: this.$t("lbl_document_reference"),
          dataIndex: "documentReference",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_shipment_id"),
          dataIndex: "shipmentId",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_equipment"),
          dataIndex: "equipment",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_brand"),
          dataIndex: "merk",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_spec"),
          dataIndex: "specification",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_sales_name"),
          dataIndex: "salesName",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_price"),
          dataIndex: "price",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_revenue_account"),
          dataIndex: "revenueAccount",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_discount"),
          dataIndex: "discountValue",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_base_amount"),
          dataIndex: "baseAmount",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_include_pph"),
          key: "includePph",
          scopedSlots: { customRender: "includePph" },
        },
        {
          title: this.$t("lbl_income_tax"),
          dataIndex: "incomeAccountTax",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_tax_code"),
          dataIndex: "taxCode",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_tax_amount"),
          dataIndex: "taxValue",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_sub_total"),
          dataIndex: "subTotal",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          customRender: (text: string) => text ?? "-",
        },
      ],
      prepaymentColumns: [
        {
          title: this.$t("common.number-text", {
            text: this.$t("lbl_invoice_prepayment"),
          }),
          dataIndex: "invoicePrepaymentNo",
        },
        {
          title: this.$t("lbl_applied_amount"),
          dataIndex: "appliedAmount",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          customRender: (text: string) => text || "-",
        },
      ],
      modalStatus: {
        show: false,
        title: "",
        data: [] as TruckingAccountReceivableFormStatusDetailInvoiceState[],
      },
      modalPrint: {
        show: false,
      },
    };
  },
  computed: {
    totalGross(): number {
      return this.state.invoiceARLines.reduce((a, b) => {
        return new Decimal(b.price).times(b.qty).plus(a).toNumber();
      }, 0);
    },
  },
  mounted() {
    this.getDetail(this.id);
  },
  methods: {
    async getDetail(invoiceId: string) {
      const { findById } = useTruckingAccountReceivable();
      this.loading = true;
      const response = await findById(invoiceId);
      this.loading = false;
      this.state = response;
    },
    getSalesOrders() {
      const docs: string[] =
        this.state.salesOrders?.map(item => item.documentNumber) || [];

      return docs.length > 0 ? docs.join(", ") : "-";
    },
    handleStatusDetailView(key: "prepayment" | "creditMemo" | "paidStatus") {
      let data: TruckingAccountReceivableFormStatusDetailInvoiceState[] = [];
      let title = "";
      if (key === "prepayment") {
        data = this.state.applyPrepayment.prepaymentLines.map(item => ({
          amount: item.appliedAmount,
          invoiceDate: item.invoicePrepaymentDate,
          invoiceNumber: item.invoicePrepaymentNo,
          key: item.id,
        }));
        title = this.$t("lbl_prepayment_detail").toString();
      } else if (key === "creditMemo") {
        data = this.state.applyCredit.map(item => ({
          amount: item.creditAmount,
          invoiceDate: item.invoiceCreditDate,
          invoiceNumber: item.invoiceCreditNo,
          key: generateUUID(),
        }));
        title = this.$t("lbl_credit_memo_detail").toString();
      } else if (key === "paidStatus") {
        data = this.state.invoiceARReceiptDetails.map(item => ({
          amount: item.receiptAmount,
          invoiceDate: item.receiptDate,
          invoiceNumber: item.receiptNumber,
          key: generateUUID(),
        }));
        title = this.$t("lbl_paid_status").toString();
      }
      this.modalStatus.title = title;
      this.modalStatus.show = true;
      this.modalStatus.data = data;
    },
    handleCloseInvoiceStatusModal() {
      this.modalStatus.show = false;
      this.modalStatus.title = "";
      this.modalStatus.data = [];
    },
    handleBack() {
      this.$router.push({ name: "trucking.account-receivable.list" });
    },
    handlePrint() {
      this.modalPrint.show = true;
    },
  },
});
