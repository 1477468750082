var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            title: _vm.$t("common.detail-text", {
              text: _vm.$t("lbl_invoice_ar")
            }),
            loading: _vm.loading
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { lg: 12, xs: 24 } },
                [
                  _c(
                    "a-descriptions",
                    { attrs: { column: 1, bordered: "" } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_invoice_number") } },
                        [_vm._v(" " + _vm._s(_vm.state.documentNumber) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_invoice_type") } },
                        [_vm._v(" " + _vm._s(_vm.state.invoiceType) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_invoice_source") } },
                        [_vm._v(" " + _vm._s(_vm.state.invoiceSource) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_invoice_date") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("date")(_vm.state.invoiceDate)) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_branch") } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.state.branchWarehouseName) + " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_currency") } },
                        [_vm._v(" " + _vm._s(_vm.state.currency) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_customer") } },
                        [_vm._v(" " + _vm._s(_vm.state.customerName) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        {
                          attrs: { label: _vm.$t("lbl_customer_ship_address") }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.state.customerShipToAddress) + " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        {
                          attrs: { label: _vm.$t("lbl_customer_bill_address") }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.state.customerBillToAddress) + " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_sales_order") } },
                        [_vm._v(" " + _vm._s(_vm.getSalesOrders()) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { lg: 12, xs: 24 } },
                [
                  _c(
                    "a-descriptions",
                    { attrs: { column: 1, bordered: "" } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_tax_calculation") } },
                        [_vm._v(" " + _vm._s(_vm.state.taxType) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_term_of_payment") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tc(
                                  "common.top-text",
                                  _vm.state.termOfPayment,
                                  { text: _vm.state.termOfPayment }
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_accounting_date") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("date")(_vm.state.accountingDate)) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_receivable_account") } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.state.receivableAccount) + " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_operator_name") } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.state.operatorName || "-") + " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_assignee") } },
                        [_vm._v(" " + _vm._s(_vm.state.assignedBy) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_invoice_description") } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.state.description || "-") + " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_status") } },
                        [_vm._v(" " + _vm._s(_vm.state.status) + " ")]
                      ),
                      _vm.state.journalId
                        ? _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_journal_number") } },
                            [
                              _c(
                                "RouterLink",
                                {
                                  staticClass: "text-llink",
                                  attrs: {
                                    to: {
                                      name: "generaljournal.detail",
                                      params: { id: _vm.state.journalId }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.state.journalNo) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-tabs",
                    { staticClass: "my-3", attrs: { type: "card" } },
                    [
                      _c(
                        "a-tab-pane",
                        {
                          key: "unit",
                          attrs: { tab: _vm.$t("lbl_unit_code") }
                        },
                        [
                          _c("a-table", {
                            attrs: {
                              "data-source": _vm.state.invoiceARLines,
                              columns: _vm.columns,
                              size: "small",
                              pagination: false,
                              "row-key": "id",
                              scroll: { x: 3800 },
                              "row-class-name": function(_record, index) {
                                return index % 2 ? "bg-white" : "bg-gray-light"
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "includePph",
                                fn: function(text, record) {
                                  return [
                                    _c("DisplayBoolean", {
                                      attrs: { value: record.includePPh }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "footer",
                                fn: function() {
                                  return [
                                    _c("div", { staticClass: "text-right" }, [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("lbl_total_items", {
                                                total:
                                                  _vm.state.invoiceARLines
                                                    .length
                                              })
                                            ) +
                                            " "
                                        )
                                      ])
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-tab-pane",
                        { key: "tax", attrs: { tab: _vm.$t("lbl_tax") } },
                        [
                          _c(
                            "a-row",
                            { attrs: { typ: "flex" } },
                            [
                              _c(
                                "a-col",
                                { attrs: { lg: 12, xs: 24 } },
                                [
                                  _c(
                                    "a-descriptions",
                                    {
                                      attrs: {
                                        column: 1,
                                        bordered: "",
                                        size: "small"
                                      }
                                    },
                                    [
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_customer_tax_type"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.state.customerTaxType
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_tax_registration_number"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.state.taxRegistrationNumber
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_tax_registration_name"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.state.taxRegistrationName
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_tax_invoice_uploaded"
                                            )
                                          }
                                        },
                                        [
                                          _c("DisplayBoolean", {
                                            attrs: {
                                              value: _vm.state.taxIsUploaded
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_tax_invoice_date"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("date")(
                                                  _vm.state.taxInvoiceDate
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_tax_invoice_number"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.state.taxInvoiceNumber
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-tab-pane",
                        {
                          key: "prepayment",
                          attrs: { tab: _vm.$t("lbl_prepayment") }
                        },
                        [
                          _c("a-table", {
                            attrs: {
                              columns: _vm.prepaymentColumns,
                              "data-source":
                                _vm.state.applyPrepayment.prepaymentLines,
                              pagination: false,
                              "row-key": "id",
                              size: "small",
                              "row-class-name": function(_record, index) {
                                return index % 2 ? "bg-white" : "bg-gray-light"
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("lbl_prepayment_used_amout")
                                          ) +
                                          ": " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.state.prepaymentUsed
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "footer",
                                fn: function() {
                                  return [
                                    _c("div", { staticClass: "text-right" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("lbl_total_items", {
                                              total:
                                                _vm.state.applyPrepayment
                                                  .prepaymentLines.length
                                            })
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-tab-pane",
                        { key: "status", attrs: { tab: _vm.$t("lbl_status") } },
                        [
                          _c(
                            "a-row",
                            { attrs: { type: "flex" } },
                            [
                              _c(
                                "a-col",
                                { attrs: { lg: 12, xs: 24 } },
                                [
                                  _c(
                                    "a-descriptions",
                                    {
                                      attrs: {
                                        column: 1,
                                        bordered: "",
                                        size: "small"
                                      }
                                    },
                                    [
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: { label: _vm.$t("lbl_total") }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.state.dpp
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_prepayment_used")
                                          }
                                        },
                                        [
                                          _c(
                                            "a-row",
                                            {
                                              attrs: {
                                                type: "flex",
                                                justify: "space-between"
                                              }
                                            },
                                            [
                                              _c("a-col", [
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          _vm.state
                                                            .prepaymentUsed
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ]),
                                              _c("a-col", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-link",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleStatusDetailView(
                                                          "prepayment"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("lbl_view")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_remaining_invoice_amount"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.state
                                                    .remainingInvoiceAmount
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_credit_memo")
                                          }
                                        },
                                        [
                                          _c(
                                            "a-row",
                                            {
                                              attrs: {
                                                type: "flex",
                                                justify: "space-between"
                                              }
                                            },
                                            [
                                              _c("a-col", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.state.creditMemoUsed
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]),
                                              _c("a-col", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-link",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleStatusDetailView(
                                                          "creditMemo"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("lbl_view")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_return_amount")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.state.returnAmount
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_receivable_account"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.state.receivableAccount
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_paid_status")
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-link",
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleStatusDetailView(
                                                    "paidStatus"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_view")) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_join_invoice")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.state.invoiceJoinNo || "-"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              attrs: {
                type: "flex",
                gutter: [16, 16],
                justify: "space-between"
              }
            },
            [
              _c(
                "a-col",
                [
                  _c("a-statistic", {
                    attrs: {
                      title: _vm.$t("lbl_total"),
                      value: _vm.totalGross
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "formatter",
                        fn: function(ref) {
                          var value = ref.value
                          return [
                            _vm._v(
                              " " + _vm._s(_vm._f("currency")(value)) + " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c("a-statistic", {
                    attrs: {
                      title: _vm.$t("lbl_discount"),
                      value: _vm.state.discountValue
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "formatter",
                        fn: function(ref) {
                          var value = ref.value
                          return [
                            _vm._v(
                              " " + _vm._s(_vm._f("currency")(value)) + " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c("a-statistic", {
                    attrs: {
                      title: _vm.$t("lbl_total_tax"),
                      value: _vm.state.vat
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "formatter",
                        fn: function(ref) {
                          var value = ref.value
                          return [
                            _vm._v(
                              " " + _vm._s(_vm._f("currency")(value)) + " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c("a-statistic", {
                    attrs: {
                      title: _vm.$t("lbl_total_prepayment"),
                      value: _vm.state.prepaymentUsed
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "formatter",
                        fn: function(ref) {
                          var value = ref.value
                          return [
                            _vm._v(
                              " " + _vm._s(_vm._f("currency")(value)) + " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c("a-statistic", {
                    attrs: {
                      title: _vm.$t("lbl_grand_total"),
                      value: _vm.state.grandTotal
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "formatter",
                        fn: function(ref) {
                          var value = ref.value
                          return [
                            _vm._v(
                              " " + _vm._s(_vm._f("currency")(value)) + " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { staticClass: "mt-5", attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", "html-type": "button" },
                          on: { click: _vm.handlePrint }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalStatus.title,
            width: "50%",
            "destroy-on-close": ""
          },
          on: { cancel: _vm.handleCloseInvoiceStatusModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "a-button",
                    { on: { click: _vm.handleCloseInvoiceStatusModal } },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.modalStatus.show,
            callback: function($$v) {
              _vm.$set(_vm.modalStatus, "show", $$v)
            },
            expression: "modalStatus.show"
          }
        },
        [
          _c("FormTruckingInvoiceStatusView", {
            attrs: { data: _vm.modalStatus.data }
          })
        ],
        1
      ),
      _c("InvoicePrintModal", {
        attrs: { "doc-id": _vm.id },
        model: {
          value: _vm.modalPrint.show,
          callback: function($$v) {
            _vm.$set(_vm.modalPrint, "show", $$v)
          },
          expression: "modalPrint.show"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }